import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function HelpDialogContent() {
  const { navConfig } = useSelector((state) => state.navConfig);
  const { mailContact, phoneContact } = navConfig.help;
  return (
    <Box sx={{ mb: "3em" }}>
      <Typography>
        Please click&nbsp;
        <a
          target="_blank"
          href={`${process.env.PUBLIC_URL}/resources/MO-INDUSTRY-TM.pdf`}
          rel="noreferrer"
        >
          here to view the training material
        </a>
      </Typography>
      <Typography>
        Please contact us at&nbsp;
        <a href={`mailto:${mailContact}`} target="_top">
          {mailContact}
        </a>{" "}
        {phoneContact && (
          <>
            <span> or </span>
            <a href={`tel:${phoneContact}`}>{phoneContact}</a>{" "}
          </>
        )}
        for any <span>further</span> assistance. Thank you!
      </Typography>
    </Box>
  );
}

export default HelpDialogContent;
