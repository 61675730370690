import React from "react";
import Typography from "@mui/material/Typography";

function GettingStartedContent() {
  return (
    <Typography>
      <strong>Welcome to Missouri Medical Marijuana Portal!</strong>
    </Typography>
  );
}
export default GettingStartedContent;
